<template>
  <PageContent
    class="etiquettes"
    page-title="Etiquettes"
  >
    <template #header-right>
      <ContextMenu
        :bloc-actions-globales="actionGlobales"
        @click-action="handleActions($event)"
      />
    </template>
    <template #action-bar>
      <div id="info_etab">
        <div>
          <span>{{ $t('compte.compte') }}</span>
          <span>{{ organismeActuel.id_organisme }}</span>
        </div>
        <div>
          <span>{{ $t('etablissement.nom-etablissement') }}</span>
          <span>{{ organismeActuel.nom }}</span>
        </div>
        <div>
          <span>{{ $t('general.localisation') }}</span>
          <span>{{ organismeActuel.commune_livraison }}</span>
        </div>
        <div>
          <span>{{ $t('etablissement.type-etiquettes') }}</span>
          <span>{{ etiquettesData.infos?.taille }}</span>
        </div>
        <div>
          <span>{{ $t('etablissement.mention') }}</span>
          <span>{{ etiquettesData.infos?.masque }}</span>
        </div>
      </div>
      <Badge
        v-if="etiquettesData?.infos?.collageeti"
        :title="etiquettesData.infos.collageeti"
        variant="info"
        medium
      />
    </template>
    <template #content>
      <p
        id="etats_cristal_web"
        class="text-small italic"
      >
        <UisExclamationTriangle size="16" />
        {{ $t("etablissement.etats-cristal-web") }}
      </p>
      <table class="text-small table-saisie-etiquettes">
        <thead>
          <tr>
            <th
              v-for="item in tableHeader"
              :key="item.key"
            >
              <button
                class="button filter-button"
                @click="sortlLines(item.key)"
              >
                <div class="button filter-button">
                  <UisSorting size="16" />
                  <span class="text-small">{{ item.name }}</span>
                </div>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="ligne, index in lignes"
            :key="index"
          >
            <td>
              <SelectClassic
                v-model="ligne.ean"
                :label="$t('produit.ean')"
                :options="optionsEan"
                status="required"
                inline
                :internal-search="false"
                :options-are-loading="optionsAreLoading"
                @search="fetchEans($event, ligne)"
                @select="handleSelect(ligne)"
              />
            </td>
            <td>{{ ligne.ean?.libelle || "-" }}</td>
            <td>
              <SelectClassic
                v-model="ligne.etat"
                :label="$t('produit.etat')"
                status="required"
                :options="menuEtats"
                inline
                @select="handleSelect(ligne)"
              />
            </td>
            <td>
              <InputClassic
                v-model="ligne.quantite"
                :label="$t('general.quantite')"
                status="required"
                type="number"
                inline
              />
            </td>
            <td>
              <InputClassic
                v-model="ligne.date"
                :label="$t('produit.date-achat')"
                status="required"
                type="date"
                :min="datesRange.min"
                :max="datesRange.max"
                inline
              />
            </td>
            <td>
              <template v-if="ligne.quantite">
                {{ ligne.quantite * etiquettesData.valeurs.valeur | moneyFilter }}{{ "\xa0" }}€
              </template>
              <template v-else>
                -
              </template>
            </td>
            <td>
              <ButtonClassic
                variant="solid"
                color="primary"
                icon="left"
                size="small"
                @click="deleteLine(index)"
              >
                <template #left-icon>
                  <UilTrashAlt />
                </template>
              </ButtonClassic>
            </td>
          </tr>
        </tbody>
      </table>
      <ButtonClassic
        variant="ghost"
        color="primary"
        :label="$t('liste.nouvelle-ligne')"
        icon="left"
        @click="lignes = [...lignes, {}, ]"
      >
        <template #left-icon>
          <UilPlusCircle />
        </template>
      </ButtonClassic>
      <TableTotal :object-infos="totalEstime" />
      <div class="text-right">
        <ButtonClassic
          variant="solid"
          color="warning"
          :label="$t('action.valider-commande-etiquettes')"
          :disabled="!commandePossible"
          icon="right"
          size="large"
          @click="$modal.show('modal_devis_commande')"
        >
          <template #right-icon>
            <IconFileDevisCheck />
          </template>
        </ButtonClassic>
      </div>
      <ModalDevisCommande
        source-type="etiquette"
        destination-type="commande"
        :data="{ libelle, }"
        :erreur="errorModalDevisCommande"
        @submit="passerCommandeEtiquettes($event)"
      />
    </template>
  </PageContent>
</template>

<script>
import {
  PageContent,
  ButtonClassic,
  SelectClassic,
  InputClassic,
  moneyFilter,
} from "@lde/core_lde_vue";

import ModalDevisCommande from "@/components/modals/ModalDevisCommande.vue";
import TableTotal from "@/components/table/TableTotal.vue";
import Badge from "@/components/Badge.vue";
import ContextMenu from "@/components/ContextMenu.vue";

import IconFileDevisCheck from "@/components/icons/IconFileDevisCheck.vue";

import { UilTrashAlt, UilPlusCircle } from "@iconscout/vue-unicons";

import { UisExclamationTriangle, UisSorting } from "@iconscout/vue-unicons-solid";

import Api from "@/modules/axios";

import { mapGetters } from "vuex";

/**
 * Gère la commande d'étiquettes.
 */
export default {
  name: "Etiquettes",
  components: {
    PageContent,
    ButtonClassic,
    InputClassic,
    SelectClassic,
    TableTotal,
    Badge,
    ModalDevisCommande,
    IconFileDevisCheck,
    UilTrashAlt,
    UilPlusCircle,
    UisSorting,
    UisExclamationTriangle,
    ContextMenu,
  },
  data() {
    return {
      etiquettesData: {},
      optionsEan: [],
      optionsAreLoading: false,
      tableHeader: [
        { key: "ean", name: this.$t("produit.ean") },
        { key: "designation", name: this.$t("general.designation") },
        { key: "etat", name: this.$t("produit.etat") },
        { key: "quantite", name: this.$tc("general.quantite") },
        { key: "date", name: this.$tc("produit.date-achat") },
        { key: "tarif", name: this.$tc("produit.tarif") },
      ],
      lignes: [{}, {}, {}, {}], // On veut 4 lignes vides par défaut.
      lastSort: {},
      buttonLabel: this.$t("action.valider-commande-etiquettes"),
      actionGlobales: [{
        title: "",
        actions: [{
          slug: "vider_selection",
          label: this.$t("action.vider-toute-la-selection"),
          icon: UilTrashAlt,
        }],
      }],
      errorModalDevisCommande: false,
    };
  },
  computed: {
    ...mapGetters(["organismeActuel", "hasPerm", "isGE", "user"]),
    datesRange() {
      return {
        min: new this.$moment("2010-01-01").format("YYYY-MM-DD"),
        max: new this.$moment().format("YYYY-MM-DD"),
      };
    },
    commandeEnCours() {
      const commande = [];
      this.lignes.forEach((element) => {
        const obj = JSON.parse(JSON.stringify(element));
        commande.push(obj);
      });
      return commande;
    },
    /**
     * Indique si toutes les conditions sont réunies pour soumettre une commande.
     * @returns {Boolean} true si la commande peut passer.
     */
    commandePossible() {
      const ldc = [];
      this.commandeEnCours.find((element) => {
        if (Object.keys(element).length) {
          ldc.push(element);
        }
        return false;
      });
      let commandePossible = false;
      if (ldc.length) {
        commandePossible = ldc.every((element) => element.etat && element.quantite && element.ean && element.date);
      }
      return commandePossible;
    },
    menuEtats() {
      let listeEtats = [];
      if (this.etiquettesData.etats) {
        listeEtats = this.etiquettesData.etats.liste.map((element) => ({
          label: element.lib_long,
          value: element.lib_long,
          code: element.code,
          lib: element.lib,
        }));
      }
      return listeEtats;
    },
    totalEstime() {
      const totalInfos = {
        libelle: this.$t("commande.commande-d-etiquettes"),
        total_ttc: 0,
        total_ht: 0,
      };
      if (this.etiquettesData.valeurs) {
        let nombreEtiquettes = 0;
        this.commandeEnCours.forEach((element) => {
          if (element.quantite) {
            nombreEtiquettes += parseInt(element.quantite, 10);
          }
        });
        totalInfos.total_ttc = nombreEtiquettes * this.etiquettesData.valeurs.valeur;
        totalInfos.total_ht = totalInfos.total_ttc;
      }
      return totalInfos;
    },
    libelle() {
      const msg = this.$t("commande.commande-web-mme-m");
      const nom = `${this.user.contact.prenom} ${this.user.contact.nom}`;
      const today = this.$moment().format("DD-MM-YYYY");

      return `${msg} ${nom} ${today}`;
    },
  },
  watch: {
    lignes: {
      handler(val) {
        localStorage.setItem(`lignesEti${this.organismeActuel.id_organisme}`, JSON.stringify(val));
      },
      deep: true,
    },
  },
  created() {
    this.fetchEtiquettes().then(() => {
      const lignesEti = JSON.parse(localStorage.getItem(`lignesEti${this.organismeActuel.id_organisme}`));
      if (lignesEti && lignesEti.length) {
        this.lignes = lignesEti;
      }
    });
  },
  methods: {
    moneyFilter,
    /**
     * Supprime ou vide une ligne du tableau.
     * @param {Number} index Index de la ligne à supprimer ou à vider.
     */
    deleteLine(index) {
      if (this.lignes.length > 4) {
        this.lignes.splice(index, 1);
      } else {
        this.$set(this.lignes, index, {});
      }
    },
    /**
     * Met la quantité de la ligne sélectionnée à 1 et gère le type date.
     * @param {Object} selectedLine Ligne sélectionnée.
     */
    handleSelect(selectedLine) {
      if (!selectedLine.quantite) {
        this.$set(selectedLine, "quantite", 1);
      }
      if (!selectedLine.date) {
        this.$set(selectedLine, "date", this.$moment().format("YYYY-MM-DD"));
      }
    },
    /**
     * Trie les éléments du tableau.
     * @param {String} sortKey Nom du champ sur lequel trier les offres.
     */
    sortlLines(sortKey) {
      const filledLines = [];
      const emptyLines = [];
      this.lignes.forEach((ligne) => {
        if (sortKey === "designation" && ligne.ean) {
          // Permet de simplifier le tri.
          ligne.designation = ligne.ean.libelle;
        }
        if (sortKey === "tarif" && ligne.quantite) {
          // Tarif est calculé et n'est pas présent par défaut dans l'objet.
          ligne.tarif = ligne.quantite * this.etiquettesData.valeurs.valeur;
        }
        if (Object.keys(ligne).includes(sortKey)) {
          filledLines.push(ligne);
        } else {
          emptyLines.push(ligne);
        }
      });

      if (this.lastSort.key !== sortKey) {
        // Si le dernier tri n'est pas sur la même colonne, on trie par défaut.
        switch (sortKey) {
          case "ean":
            filledLines.sort((a, b) => {
              if (a.ean.label < b.ean.label) return -1;
              if (a.ean.label > b.ean.label) return 1;
              return 0;
            });
            break;
          case "designation":
            // On doit prendre en compte les accents pour les titres et les états.
            filledLines.sort((a, b) => a[sortKey].localeCompare(b[sortKey], "fr", { ignorePunctuation: true }));
            this.lastSort.asc = true;
            break;
          case "etat":
            // On doit prendre en compte les accents pour les titres et les états.
            filledLines.sort((a, b) => a[sortKey].label.localeCompare(b[sortKey].label, "fr"));
            this.lastSort.asc = true;
            break;
          case "quantite":
            filledLines.sort((a, b) => {
              const quantiteA = parseInt(a.quantite, 10);
              const quantiteB = parseInt(b.quantite, 10);
              if (quantiteA < quantiteB) return -1;
              if (quantiteA > quantiteB) return 1;
              return 0;
            });
            break;
          case "date":
            filledLines.sort((a, b) => {
              const dateA = parseInt(a.date.replace(/-/g, ""), 10);
              const dateB = parseInt(b.date.replace(/-/g, ""), 10);
              if (dateA < dateB) return -1;
              if (dateA > dateB) return 1;
              return 0;
            });
            break;
          case "tarif":
            filledLines.sort((a, b) => {
              if (a.tarif < b.tarif) return -1;
              if (a.tarif > b.tarif) return 1;
              return 0;
            });
            break;
          default:
            break;
        }
      } else {
        // Si le dernier tri est sur la même colonne, on effectue l'inverse du précédent tri
        filledLines.reverse();
        this.lastSort.asc = !this.lastSort.asc;
      }
      this.lastSort.key = sortKey;
      this.lignes = [...filledLines, ...emptyLines];
    },
    /**
     * Récupère les informations sur les étiquettes.
     * @returns {Object} Contient le détail, les valeurs et les états Cristal Web.
     */
    fetchEtiquettes() {
      this.optionsAreLoading = true;
      const url = `/organisme/${this.organismeActuel.id}/infos_etiquettes/`;
      return Api().get(url)
        .then(({ data }) => {
          this.etiquettesData = data;
          this.optionsAreLoading = false;
        })
        .catch((error) => {
          this.$toast.error({ title: error });
        });
    },
    /**
     * Récupère les EAN avec leurs informations selon le texte tapé.
     * @param {String} searchText Texte tapé dans l'input.
     * @param {String} ligne Ligne sur laquelle on recherche une offre.
     */
    fetchEans(searchText, ligne) {
      this.optionsAreLoading = true;
      const searchtextFormatted = searchText.replaceAll("-", "").replaceAll(" ", "");
      Api().get(`/papier/?ean__icontains=${searchtextFormatted}&page_size=5`)
        .then(({ data }) => {
          this.optionsEan = data.results.map((res) => ({
            id: res.id,
            label: res.ean,
            value: res.ean,
            libelle: res.libelle,
          }));
          this.optionsAreLoading = false;
          if (this.optionsEan.length === 1 && this.optionsEan[0].label.length === searchtextFormatted.length) {
            const index = this.lignes.indexOf(ligne);
            this.lignes[index].ean = this.optionsEan[0];
            this.handleSelect(ligne);
          }
        });
    },
    /**
     * Passe la commande d'étiquette.
     * @param {String} libelle Nom de la commande.
     */
    passerCommandeEtiquettes(eventData) {
      if (this.commandePossible) {
        const datesPossibles = this.lignes.every(
          (ligne) => this.$moment(ligne.date).isBetween("2010-01-01", this.$moment(), "days", "[]"),
        );
        if (datesPossibles) {
          this.$toast.info({
            title: this.$t("toast.envoi-en-cours"),
            content: this.$t("toast.veuillez-patienter"),
          });
          const commande = {
            libelle: (eventData.libelle || this.libelle),
            lignes: [],
            num_engagement: eventData.num_engagement,
          };

          this.commandeEnCours.forEach((element) => {
            if (Object.keys(element).length) {
              commande.lignes.push({
                ean: element.ean.value,
                qte: element.quantite,
                etatCode: element.etat.code,
                etatLib: element.etat.lib,
                dateAchat: element.date,
              });
            }
          });
          Api()
            .post("/commande/commander_etiquettes/", commande)
            .then(({ data }) => {
              if (typeof data === "object") {
                this.lignes = [{}, {}, {}, {}];
                this.$toast.success({ title: this.$t("commande.commande-pris-en-compte-delais") });
              } else {
                this.$toast.error(this.$t("info.une-erreur-est-survenue"));
              }
              // Redirection vers l'accueil
              this.$router.push({ name: "home" });
            })
            .catch((err) => {
              this.$toast.error({ title: err.response?.data || this.$t("info.une-erreur-est-survenue") });
              this.errorModalDevisCommande = true;
            });
        } else {
          this.$toast.error({
            title: this.$t("toast.date-selectionnee-doit-etre-entre-le-x-et-aujourdhui", {
              date: this.$moment("01-01-2010").format(this.$t("global.format-date")),
            }),
          });
        }
      } else {
        this.$toast.error({ title: this.$t("toast.veuillez-remplir-champs") });
      }
    },
    handleActions(action) {
      switch (action.slug) {
        case "vider_selection":
          this.lignes = [{}, {}, {}, {}];
          return true;
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_etiquettes.scss";
</style>
